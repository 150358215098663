.helpWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.helpWrapper > p {
  width: 75%;
}

.helpH1 {
  margin: 25px 0;
}

.dictionaryh3 {
  margin: 50px 0;
}

.dictionaryWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dictionaryWrapper > div {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.exampleImageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 50px;
}

.exampleImage {
  border-radius: 12px;
}

.exampleImageDark {
  border: 2px solid #ffffff;
}

.exampleImageWrapper > div {
  margin: 20px 0;
}

.ant-image-mask {
  border-radius: 12px;
}

.exampleImageWrapper > div > div {
  width: 750px;
}

@media only screen and (max-width: 460px) {
  .exampleImage {
    max-width: 350px;
    width: 100%;
  }

  .exampleImageWrapper > div > div {
    max-width: 350px;
    width: 100%;
  }
}

@media only screen and (min-width: 461px) and (max-width: 768px) {
  .exampleImage {
    max-width: 420px;
    width: 100%;
  }

  .exampleImageWrapper > div > div {
    max-width: 420px;
    width: 100%;
  }
}
