.infoWrapper {
  width: 50%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 25px 0;
}

.info {
  width: 33%;
}
