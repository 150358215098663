.calculator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleDark {
  padding-top: 20px;
  color: white;
}

.titleLight {
  padding-top: 20px;
  color: black;
}

.titleCalendarDark {
  color: white;
}

.titleCalendarLight {
  color: black;
}

.titleCalendar {
  width: 50%;
  text-align: center;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.inputDiv {
  display: flex;
  flex-direction: column;
}

.inputWrapper > .inputDiv,
.inputWrapper > .dateInput {
  width: 100%;
  max-width: 200px;
  height: 30px;
  margin-bottom: 50px;
}

.inputDiv > div,
.inputDiv > input {
  border-radius: 10px;
  border: 2px solid #3a3a3a6c;
}

.resultWrapper {
  width: 50%;
  display: flex;
  align-items: baseline;
  margin: 4px 0;
  border-radius: 12px;
  padding: 5px 0;
}

.darkResult {
  background-color: #cbcbcb66;
}

.lightResult {
  background-color: #97979766;
}

.result {
  width: 20%;
}

.continueP {
  text-align: center;
}

.calculatorResult {
  margin-bottom: 5%;
}

.copiedSVG {
  color: #576ec0;
  font-size: 36px;
}

.tooltipShare {
  margin-left: 10px;
  color: #576ec0;
}

.ant-tooltip-open {
  color: #576ec07c;
}

@media only screen and (max-width: 461px) {
  .calculatorResult {
    margin-bottom: 15%;
  }
}

@media only screen and (min-width: 461px) and (max-width: 768px) {
  .calculator > .resultWrapper {
    width: 90%;
    font-size: 17.5px;
  }
}

@media only screen and (max-width: 1024px) {
  .calculator > h1 {
    width: 100%;
    font-size: 25px;
    text-align: center;
  }

  .calculator > .infoWrapper {
    width: 100%;
    text-align: center;
  }

  .calculator > .headerWrapper {
    width: 90%;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .calculator > .resultWrapper {
    width: 95%;
    font-size: 15px;
  }

  .calculator > .resultWrapper > .result {
    text-align: center;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
