.logoWrapper {
  display: flex;
  justify-content: center;
}

.logo {
  position: fixed;
  z-index: -1;
  max-width: 650px;
  width: 100%;
  top: -20px;
  opacity: 0.35;
}
