.companyHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
}

.companyH1 {
  margin: 25px 0;
  text-align: center;
}

.goBackButton {
  margin: 25px 0 0 0;
}

.goBackButtonDark {
  color: #ffffff;
}

.goBackButtonDark:hover {
  color: #7289da;
}

.goBackButtonLight {
  color: #7289da;
}

.goBackButtonLight:hover {
  color: #7289daa4;
}

.goBackButton > svg {
  width: 40px;
  height: 40px;
}

.companyDataWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.companyDataWrapper > span {
  border-bottom: 2px solid;
  margin: 5px 0;
}

.companyDataImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.companyDataImage {
  margin-top: 25px;
  border-radius: 25px;
  width: 400px;
  background-color: white;
}

@media only screen and (max-width: 460px) {
  .companyDataImage {
    width: 200px;
    margin-bottom: 50px;
  }
}
