.aboutDivWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.aboutDivWrapper > h1 {
  text-align: center;
  margin-top: 50px;
}

.aboutDivWrapper > p {
  width: 75%;
}

.aboutDivider {
  border-top: 7px solid #7289da83;
  width: 75%;
  min-width: 75%;
}

.contactWrapper {
  width: 35%;
}

.contactWrapper > p {
  text-align: center;
}

.contactDiv {
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
}

.contactDiv > a {
  font-size: 50px;
}

.contactDiv > a:hover {
  color: #7289da;
}

.contactDivDark > a {
  color: #fff;
}

.contactDivLight > a {
  color: #000;
}

@media only screen and (max-width: 460px) {
  .aboutDivWrapper {
    padding-bottom: 30px;
  }

  .aboutDivWrapper > p {
    text-align: center;
  }

  .aboutDivWrapper > h1 {
    margin-top: 10px;
  }

  .contactWrapper {
    width: 100%;
    margin-bottom: 25px;
  }
}

@media only screen and (min-width: 461px) and (max-width: 768px) {
  .contactWrapper {
    width: 50%;
    margin-bottom: 50px;
  }
}
