.headerWrapper {
  width: 50%;
  display: flex;
  align-items: baseline;
  margin: 10px 0;
}

.header {
  width: 20%;
}
