.newsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.newsCardWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-evenly;
  width: 85%;
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .newsCardWrapper {
    justify-content: center;
    width: 100%;
  }
}
