@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");
@import "bootstrap/dist/css/bootstrap.min.css";

html {
  overflow-y: overlay;
}

.App {
  position: absolute;
  z-index: 1;
  width: 100%;
  min-height: 100vh;
  height: fit-content;
}

body {
  font-family: "Josefin Sans", sans-serif !important;
  font-size: 18px !important;
}

input {
  color: #000000;
}

@media only screen and (max-width: 1440px) {
  .App {
    min-height: 100vh;
    height: fit-content;
  }
}
