.newsCardTitle {
  padding: 0 20px;
}

.newsCardCol {
  margin: 0 10px 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.newsCard {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  text-align: center;
  border-radius: 20px;
}

.newsImage {
  width: 100%;
  max-width: 280px;
  height: auto;
}

.ant-card-body {
  height: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 461px) {
  .newsCardCol {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 462px) and (max-width: 768px) {
  .newsCardCol {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 1024px) {
}
