.navbar {
  background-color: #f8f9fa00 !important;
  display: flex;
}

.navbar-toggler:focus {
  outline: none;
}

.navbar-collapse {
  flex-grow: 1 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.darkLink {
  color: #fff !important;
}

.lightLink {
  color: #2c2f33 !important;
}

.linkWrapper {
  text-align: center;
  padding-top: 20px;
}

.darkLink,
.lightLink {
  margin: 0 10px;
  padding: 10px;
  border: 2px solid #ffffff00;
}

.darkLink:hover,
.lightLink:hover,
.darkLink:focus,
.lightLink:focus {
  color: #ffa500 !important;
}

.themeButton {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: #00000000;
  border: 2px solid rgba(128, 128, 128, 0.178);
  border-radius: 25%;
  cursor: pointer;
  width: 50px;
  height: 50px;
  font-size: 25px;
  padding: 5px;
}

@media only screen and (max-width: 991px) {
  .darkNavbar {
    background-color: #52525270 !important;
  }

  .lightNavbar {
    background-color: #e0e0e070 !important;
  }

  .darkHr {
    border: 1px solid #fff;
  }

  .lightHr {
    border: 1px solid #000;
  }
}
