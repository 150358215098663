.dictionary {
  width: 100%;
}

.dictionaryTitle {
  font-weight: 600;
}

.dictionaryHr {
  border: 2px solid gray;
  width: 100%;
}

.dictionary:last-of-type {
  margin-bottom: 50px;
}

@media only screen and (max-width: 460px) {
  .dictionary {
    display: flex;
    flex-direction: column;
  }

  .dictionaryDescription {
    margin-top: 15px;
  }
}
